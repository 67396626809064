import React, { useState, useEffect } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import '../../../Assets/css/home.css';
import { Helmet } from 'react-helmet';
import BannerSlider from './../Bannerslider';

// import Insta from './insta';
// import Virtualappointment from './VirtualAppointment';
// import Gemstones from './GemstoneShop';
// import RingStyle from './RingStyle';
// import Ringbuilderhome from './Ringbuilderhome';
// import SignupTo from './SignupTo';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useSelector } from 'react-redux';
import { client_name } from '../../../Helpers/request';
import ShopByDiamondShape from './Components/ShopByDiamondShape';
import ExploreCollection from './Components/ExploreCollection';
import DesignDiamondRing from './Components/DesignDiamondRing';
import ShopRingByStyle from './Components/ShopRingByStyle';
import GemstoneGlamour from './Components/GemstoneGlamour';
import CustomDesign from './Components/CustomDesign';
import ConsultSpecialist from './Components/ConsultSpecialist';
import Promises from './Components/Promises';
import Customer from './Components/Customer';
import Insta from './Components/insta';
import SignupTo from './Components/SignupTo';
import { Container } from 'react-bootstrap';
import EngagementWeddingRings from './Components/EngagementWeddingRings';
import LooseDiamonds from './Components/LooseDiamonds';
import Member from './Components/Member';

const Home = () => {
  const metaDetails = useSelector((state) => state.persistedReducer.meta.meta);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      // disable: 'mobile'
    });
  }, []);

  return (
    <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{metaDetails?.title}</title>
            <meta name="description" content={metaDetails?.description}></meta>
            <meta name="keywords" content={metaDetails?.keywords}></meta>
            {/*Og Tags */}
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={metaDetails?.title} />
            <meta property="og:description" content={metaDetails?.description} />
            <meta property="og:url" content={metaDetails?.url} />
            <meta property="og:site_name" content={client_name?.c_name} />
        </Helmet>
        <BannerSlider />
        <Member/>
        <ShopRingByStyle />
        <DesignDiamondRing />
        <EngagementWeddingRings/>
        <LooseDiamonds/>
        <ShopByDiamondShape />
        <ExploreCollection />
     
       
        <GemstoneGlamour />
        <CustomDesign />
        {/* <ConsultSpecialist /> */}
        <Customer />
        {/* <Promises /> */}
        <Insta />
        <SignupTo />

      {/* <RingStyle />
      <ExploreCollection />
      <Ringbuilderhome /> 
      <ShopDiamondShape />
      <Gemstones />
      <Virtualappointment />
      <Customer />
      <Insta />
      <SignupTo /> */}
      
    </>
  );
};

export default Home;
