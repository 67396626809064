import React, { useState } from "react";
import {
  Button,
  Col,
  Container,
  FormControl,
  Image,
  InputGroup,
  Row,
  Form,
  Modal,
} from "react-bootstrap";
import {
  base_url,
  client_name,
  contact_number,
  currency,
  currencycode,
  helmet_url,
  postHeader,
} from "../../../../Helpers/request";
import { useHistory } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import Loader from "../../../../Components/Loader";
import Heading from "../ui/Heading";
import { TiLocation } from "react-icons/ti";
import { IoMdMail } from "react-icons/io";
import Location from '../../../../Assets/images/swales-img/Location.png'
import Call from '../../../../Assets/images/swales-img/Call.png'
import Message from '../../../../Assets/images/swales-img/Message.png'
import footerImg from '../../../../Assets/images/swales-img/footer-img.png';
import footerImgMember from '../../../../Assets/images/swales-img/member-img.webp';


function SignupTo() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const history = useHistory();
  const [newsletteremail, setNewsletteremail] = useState("");
  const [loader, setLoader] = useState(false);
  const newsletter = (e) => {
    e.preventDefault();
    setLoader(true);
    const data = {
      currency_code: currencycode,
      email: newsletteremail,
    };
    axios
      .post(base_url + "/common/subscribe_newsletter", data, {
        headers: postHeader,
      })
      .then((res) => {
        if (res.data.status == 1) {
          setLoader(false);
          setNewsletteremail("");
          return swal(res.data.message, "", "success");
        } else {
          setLoader(false);
          return swal(res.data.message, "", "error");
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  };
  return (
    <section className="rcs_signup_to_section py-5">
      <Container className="showcase-container ">
        <Row className=" d-flex align-items-center sd_align_items_tab">
          <Col sm={12} md={6}>
            <Heading className="text-left">
              <h2>Get In Touch</h2>
            </Heading>
            <div className="sd_get_in_touch_footer">
              <ul>
                <li>
                  <a href="https://www.google.com/maps/place/11+St+Paul's+Square,+Birmingham+B3+1RB,+UK/@52.485474,-1.904644,16z/data=!4m6!3m5!1s0x4870bc92bdaaf7cd:0x67ba7105a00c7d6b!8m2!3d52.4854737!4d-1.9046442!16s%2Fg%2F11fhtkqqn7?hl=en&entry=ttu" target="_blank" className="sd_get_in_touch_footer_inner">
                    <div className="sd_get_in_touch_box_inner d-flex align-items-center">
                      <div>
                     <Image src={Location} alt="Address Icon"/>
                      </div>
                      <h6>Address</h6>
                    </div>
                    <p>
                      11 St Paul's Square, <br></br>Jewellery Quarter Birmingham,<br></br> UK, B3
                      1RB
                    </p>
                  </a>
                </li>
                <li>
                  <a href="mailto:info@swalesdiamonds.co.uk"  className="sd_get_in_touch_footer_inner">
                    <div className="sd_get_in_touch_box_inner d-flex align-items-center">
                      <div>
                      <Image src={Message} alt="Message Icon"/>
                      </div>
                      <h6>Email</h6>
                    </div>
                    <p>
                        info@swalesdiamonds.co.uk
                    </p>
                  </a>
                </li>
                <li>
                  <a 
                  // onClick={() => {
                  //                   window.location.href = contact_number.link;
                  //                 }}
                                  href={contact_number.link} className="sd_get_in_touch_footer_inner">
                    <div className="sd_get_in_touch_box_inner d-flex align-items-center">
                      <div>
                      <Image src={Call} alt="Call Icon"/>
                      </div>
                      <h6>Phone</h6>
                    </div>
                    <p>
                  { contact_number.text}
                    </p>
                  </a>
                </li>
              </ul>
            </div>
            <div className="sl_signup_img_box">
            <div className="mail_online_image_box">
<Image src={footerImg} alt="Mail Online Image" className="mail_online_image img-fluid" onClick={()=>window.open("https://www.dailymail.co.uk/femail/article-13117243/Do-hear-wedding-bells-unforgettable-venues-hen-hits-10-tips-tying-knot.html", "_blank")}/>

            </div>
            <div className="mail_online_image_box1">
            <Image src={footerImgMember} alt="Member Image" className="mail_online_image img-fluid"onClick={handleShow}/>
              </div>
            </div>
           
          </Col>
          <Col sm={12} md={6} className="sd_sign_border_left">
            <Heading className="text-left p-0">
              <h2>Hear from Swales</h2>
              <p>
                be the first to hear about our new collections and latest offers
              </p>
            </Heading>
            <div className="rcs_signup_to_input_field">
              <Form className="gs_news_form" onSubmit={newsletter}>
                <InputGroup>
                  <FormControl
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    type="email"
                    value={newsletteremail}
                    placeholder="Your email address"
                    required
                    onChange={(e) => setNewsletteremail(e.target.value)}
                  />
                  <button
                    className="btn outline-base-btn large"
                    type="submit"
                    id="button-addon2"
                  >
                    Submit
                  </button>
                </InputGroup>
              </Form>
            </div>
          </Col>
          {/* <Col lg={6} xs={12} className="ml-auto pr-0 p-0"></Col> */}
        </Row>
      </Container>

      <Modal show={loader}>
        <Loader />
      </Modal>


      <Modal centered show={show}  size="lg" onHide={handleClose} className="membership_modal">
        <Modal.Header closeButton className="float-right">
          <Modal.Title>
            {/* Modal heading */}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <Image src={footerImgMember} alt="Member Image" className="img-fluid"/>
              </div>
              </Modal.Body>
        
      </Modal>
    </section>
  );
}

export default SignupTo;
