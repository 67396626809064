import React from 'react'
import Container from '../ui/Container'
import { Col, Image, NavLink, Row } from 'react-bootstrap'
import Heading from '../ui/Heading'
import { Link } from 'react-router-dom/cjs/react-router-dom'

import Setting from "../Assets/img/setting.png"
import Diamond from "../Assets/img/diamond.png"
import Diamondvideo from "../Assets/img/video.gif"
import Video from "../Assets/img/bm-video.mp4"
import Slider from 'react-slick'
import { isMobile } from 'react-device-detect'
import Startring from '../../../../Assets/images/swales-img/start-ring.png'
import Startdiamond from '../../../../Assets/images/swales-img/start-diamond.png'
import { useHistory } from 'react-router-dom'

const DesignDiamondRing = () => {
    const history = useHistory()
    const settings = {
        customPaging: function (i) {
            return (
                <a className='dots11'>
                    {i + 1}
                </a>
            );
        },
        dots: true,
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        arrows: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        // <section className='design-diamond-ring-section section-space pb-0'>
        //     <Container>
        //         <Row className='align-items-center'>
        //             <Col md={4}>
        //                 <Heading className='text-start p-0'>
        //                     <span>Design Your Own</span>
        //                     <h2>Diamond Ring</h2>
        //                     <p>Design a Diamond Ring That Reflects Your Style and Personality</p>
        //                 </Heading>
        //             </Col>

        //             <Col md={8}>
        //                 {isMobile ?
        //                     <div className='mobile-slider-ringsetting-home'>
        //                         <Slider {...settings}>
        //                         <div>
        //                             <div className='item'>
        //                                 <Image src={Setting} alt='' />
        //                                 <div className='content pl-3 pr-5'>
        //                                     {/* <span>1.</span> */}
        //                                     <h3>Choose Setting</h3>
        //                                     <p>Lorem Ipsum is a simply dummy text of the typing indsutry.</p>
        //                                     <Link to='/engagement-rings' className="btn outline-base-btn small">Start with a setting</Link>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                         <div>
        //                             <div className='item'>
        //                                 <Image src={Diamond} alt='' />
        //                                 <div className='content pl-3 pr-5'>
        //                                     {/* <span>2.</span> */}
        //                                     <h3>Choose Diamond</h3>
        //                                     <p>Lorem Ipsum is a simply dummy text of the typing indsutry.</p>
        //                                     <Link to='/diamonds' className="btn outline-base-btn small">Start with a diamond </Link>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                         <div>
        //                             <div className='item video-item'>
        //                                 <video muted autoPlay>
        //                                     <source src={Video} type="video/mp4" />
        //                                 </video>
        //                                 <div className='content'>
        //                                     {/* <span>3.</span> */}
        //                                     <h3>Complete Your Ring</h3>
        //                                     <p>Lorem Ipsum is a simply dummy text of the typing indsutry.</p>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </Slider>
        //                     </div> :
        //                     <Row>
        //                         <Col md={6}>
        //                             <div className='item d-flex align-items-end'>
        //                                 <Image src={Setting} alt='' />
        //                                 <div className='content pl-3 pr-5'>
        //                                     <span>1.</span>
        //                                     <h3>Choose Setting</h3>
        //                                     <p>Lorem Ipsum is a simply dummy text of the typing indsutry.</p>
        //                                     <Link to='/engagement-rings' className="btn outline-base-btn small">Start with a setting</Link>
        //                                 </div>
        //                             </div>
        //                             <div className='item d-flex align-items-end mt-5'>
        //                                 <Image src={Diamond} alt='' />
        //                                 <div className='content pl-3 pr-5'>
        //                                     <span>2.</span>
        //                                     <h3>Choose Diamond</h3>
        //                                     <p>Lorem Ipsum is a simply dummy text of the typing indsutry.</p>
        //                                     <Link to='/diamonds' className="btn outline-base-btn small">Start with a diamond </Link>
        //                                 </div>
        //                             </div>
        //                         </Col>
        //                         <Col md={6}>
        //                             <div className='item video-item'>
        //                                 <video muted autoPlay>
        //                                     <source src={Video} type="video/mp4" />
        //                                 </video>
        //                                 <div className='content'>
        //                                     <span>3.</span>
        //                                     <h3>Complete Your Ring</h3>
        //                                     <p>Lorem Ipsum is a simply dummy text of the typing indsutry.</p>
        //                                 </div>
        //                             </div>
        //                         </Col>
        //                     </Row>}
        //             </Col>
        //         </Row>
        //     </Container>
        // </section>

        
            <Container className="showcase-container">
            <section className='RingBuilderHome design-diamond-ring-section section-space'>
                <Row className='align-items-center'>
                    <Col md={12} xs={12}>
                        <div className='textBox text-center'>
                            <Heading className='text-center p-0'>
                                <span>Design Your Own</span>
                                <h2>Diamond Ring</h2>
                                <p className='text-center m-auto'>Design a Diamond Ring That Reflects Your Style and Personality</p>
                                {/* <div className='content mt-4'>
                                    <Link to='/engagement-rings' className="btn outline-base-btn small mr-3">Start with a setting</Link>
                                    <Link to='/diamonds' className="btn outline-base-btn small">Start with a diamond </Link>
                                </div> */}

                            </Heading>
                        </div>
                    </Col>
                </Row>
                <Row className='sd_ring_box_box_padding'>
                    <Col sm={12} md={6} lg={6}>
                        <div className='sd_start_with_setting_whitebox'>
<Image src={Startring} alt="Start with setting Image" className='img-fluid'/>
<Heading className='text-start p-0'>
                                <h2>Start With A Setting</h2>
                                <p>Browse our assortment of unique engagement ring settings.</p>
                            </Heading>
                            <div>
                                <button className='sd_btn_outline' onClick={()=> history.push("/engagement-rings")}>Select Setting</button>
                            </div>
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={6}>
                        <div className='sd_start_with_setting_whitebox diamond_box'>
<Image src={Startdiamond} alt="Start with setting Image" className='img-fluid'/>
<Heading className='text-start p-0'>
                                <h2>Start With A Diamond</h2>
                                <p>Search through our vast selection of  GIA certified diamonds to find your perfect diamond.</p>
                            </Heading>
                            <div>
                                <button className='sd_btn_outline' onClick={()=> history.push("/diamonds")}>Select Diamond</button>
                            </div>
                        </div>
                    </Col>
                </Row>
                </section>
            </Container>
     
    )
}

export default DesignDiamondRing