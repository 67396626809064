import React from 'react';
import Container from '../ui/Container';
import Heading from '../ui/Heading';
import instaImg from '../../../../Assets/images/swales-img/follow-us-insta-img.png'
import { Image } from 'react-bootstrap';
import { isMobileOnly } from 'react-device-detect';

const Insta = () => {
    return (
        <section className='insta-section section-space pb-3 pb-lg-0'>
            <Container className="showcase-container ">
                <div className='d-flex justify-content-between align-items-center'>
                <Heading className='text-left'>
                    <h2>Our Social Media</h2>
                    <p>Follow  <a href='https://www.instagram.com/swalesdiamonds/' className='text-dark' target='_blank'><strong>@swalesdiamonds</strong></a> on Instagram</p>
                </Heading>
                <div className='sd_insta_img d-none d-lg-block d-md-block'>
                <a href='https://www.instagram.com/swalesdiamonds/'  target='_blank'><Image src={instaImg} alt="insta Image"/></a> 
                </div>
                </div>
                
                <iframe src="https://instagram.demobw.live/swalesdiamonds/" frameborder="0" width="100%" class="insta-iframe"></iframe>

             { isMobileOnly ?  <div className='sd_insta_img text-center'>
                <a href='https://www.instagram.com/swalesdiamonds/'  target='_blank'><Image src={instaImg} alt="insta Image"/></a> 
                </div> : ""
             }   
            </Container>
        </section>
    )
}

export default Insta;