import React from 'react'
import Container from '../ui/Container'
import { Col, Image, Row } from 'react-bootstrap'
import Heading from '../ui/Heading'

import Red from "../Assets/img/gemstone/Red_Ruby.png"
import Blue from "../Assets/img/gemstone/Blue_Sapphire.png"
import Pink from "../Assets/img/gemstone/Pink_Sapphire.png"
import Yellow from "../Assets/img/gemstone/Yellow_Sapphire.png"
import Green from "../Assets/img/gemstone/Green_Emerald.png"
import Moissanite from "../Assets/img/gemstone/Moissanite.png"
import { Link } from 'react-router-dom/cjs/react-router-dom'

const GemstoneGlamour = () => {
  return (
    <section className='gemstone-glamour section-space'>
        <Container>
             <Row>
                <Col md={6}>
                    <Heading className='pb-2 text-center'>
                        {/* <span>Colorful Charms</span> */}
                        <h2>HAND CRAFTED ENGAGEMENT RINGS</h2>
                        <p>Your journey to forever starts here with our exquisite range of hand crafted engagement rings. Our knowledgeable and experienced team will work with you to bespoke and craft your dream
ring, encompassing your ideas, aspirations and vision to help create the perfect forever ring. We are proud to offer diamonds that are responsibly sourced and certificated, which are then
expertly crafted by our experienced manufacturing team, to help bring your vision to life. </p>
                    </Heading>

                    {/* <div className='gemstone-wrapper d-flex align-items-center justify-content-around'>
                        <div className='item'>
                            <Image src={Red} alt='' />
                            <p>Red Ruby</p>
                        </div>
                        <div className='item'>
                            <Image src={Blue} alt='' />
                            <p>Blue Sapphire</p>
                        </div>
                        <div className='item'>
                            <Image src={Pink} alt='' />
                            <p>Pink Sapphire</p>
                        </div>
                        <div className='item'>
                            <Image src={Yellow} alt='' />
                            <p>Yellow Sapphire</p>
                        </div>
                        <div className='item'>
                            <Image src={Green} alt='' />
                            <p>Green Emerald</p>
                        </div>
                        <div className='item'>
                            <Image src={Moissanite} alt='' />
                            <p>Moissanite</p>
                        </div>
                    </div> */}

                    <div className='text-center pt-3'>
                        <Link to='/engagement-rings' className="sd_btn_outline" style={{color:"#000", borderColor:"#000"}}>View Collection</Link>
                    </div>
                </Col>
             </Row>
        </Container>
    </section>
  )
}

export default GemstoneGlamour