import React from 'react'
import Container from '../ui/Container'
import Heading from '../ui/Heading'
import { Col, Image, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom/cjs/react-router-dom'

// import Ring from "../Assets/img/Rings.jpg";
// import Bracelet from "../Assets/img/Bracelets.jpg";
// import Earring from "../Assets/img/Earrings.jpg";
// import Necklace from "../Assets/img/Necklaces.jpg";
import SlickLeft from "../../../../Assets/images/swales-img/left-arrow.png"
import SlickRight from "../../../../Assets/images/swales-img/right-arrow.png"

import Ring from "../../../../Assets/images/swales-img/fine-jewellery-img/ring.png"
import Necklaces from "../../../../Assets/images/swales-img/fine-jewellery-img/necklace.png"
import Bracelets from "../../../../Assets/images/swales-img/fine-jewellery-img/braclate.png"
import Pendants from "../../../../Assets/images/swales-img/fine-jewellery-img/pandant.png"
import Earrings from "../../../../Assets/images/swales-img/fine-jewellery-img/earing.png"
import Slider from 'react-slick'

const ExploreCollection = () => {
    let settingsCollection = {
        dots: false,
        infinite: true,
        speed: 500,
        accessibility: true,
        arrows: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: false,
        prevArrow: <Image src={SlickLeft} alt='' />,
        nextArrow: <Image src={SlickRight} alt=''  />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    arrows: false,
                    dots: true,
                }
            }
        ]
    };
    return (
        <section className='explore-collection section-space'>
            <Container>
                <Heading className='pb-2 text-center'>
                    <h2>Shop Fine Jewellery</h2>
                    {/* <p>Find Exactly What You Need</p> */}
                </Heading>

                {/* <Row>
                    <Col md={3} xs={6}>
                        <div className='item text-center'>
                            <Link to='/jewellery/fashion-rings' className='img-box'><Image src={Ring} alt='' /></Link>
                            <Link to='/jewellery/fashion-rings'>Rings</Link>
                        </div>
                    </Col>
                    <Col md={3} xs={6}>
                        <div className='item text-center'>
                            <Link to='/jewellery/bracelets' className='img-box'><Image src={Bracelet} alt='' /></Link>
                            <Link to='/jewellery/bracelets'>Bracelets</Link>
                        </div>
                    </Col>
                    <Col md={3} xs={6}>
                        <div className='item text-center'>
                            <Link to='/jewellery/earrings' className='img-box'><Image src={Earring} alt='' /></Link>
                            <Link to='/jewellery/earrings'>Earrings</Link>
                        </div>
                    </Col>
                    <Col md={3} xs={6}>
                        <div className='item text-center'>
                            <Link to='/jewellery/necklaces' className='img-box'><Image src={Necklace} alt='' /></Link>
                            <Link to='/jewellery/necklaces'>Necklaces</Link>
                        </div>
                    </Col>
                </Row> */}
                 <Slider {...settingsCollection}>
                <div className='item'>
                <Link to='/jewellery/fashion-rings'><Image src={Ring} alt="Ring" /></Link>
                    <Link to='/jewellery/fashion-rings'>Rings</Link>
                </div>
                <div className='item'>
                <Link to='/jewellery/necklaces'><Image src={Necklaces} alt="Necklaces" /></Link>
                    <Link to='/jewellery/necklaces'>Necklaces</Link>
                </div>
                <div className='item'>
                <Link to='/jewellery/bracelets'><Image src={Bracelets} alt="Bracelets" /></Link>
                    <Link to='/jewellery/bracelets'>Bracelets</Link>
                </div>
                <div className='item'>
                <Link to='/jewellery/pendants'><Image src={Pendants} alt="Pendants" /></Link>
                    <Link to='/jewellery/pendants'>Pendants</Link>
                </div>
                <div className='item'>
                <Link to='/jewellery/earrings'><Image src={Earrings} alt="Earrings" /></Link>
                    <Link to='/jewellery/earrings'>Earrings</Link>
                </div>
                {/* <div className='item'>
                <Link to='/engagement-rings/style=Pave'><Image src={pave} alt="pave" /></Link>
                    <Link to='/engagement-rings/style=Pave'>pave</Link>
                </div> */}
                {/* <div className='item'>
                <Link to='/engagement-rings/style=Multirow'><Image src={pave} alt="Pave" /></Link>
                    <Link to='/engagement-rings/style=Multirow'>Pave</Link>
                </div>
                <div className='item'>
                <Link to='/engagement-rings/style=Bypass'><Image src={ByPass} alt="Bypass" /></Link>
                    <Link to='/engagement-rings/style=Bypass'>Bypass</Link>
                </div>
                <div className='item'>
                <Link to='/engagement-rings/style=Solitaire'><Image src={Solitaire} alt="Solitaire" /></Link>
                    <Link to='/engagement-rings/style=Solitaire'>Solitaire</Link>
                </div> */}
            </Slider>
            </Container>
        </section>
    )
}

export default ExploreCollection