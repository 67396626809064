import React from 'react';
import { Row, Col, Button, Image } from 'react-bootstrap';
import Gimg from "../../../../Assets/images/home/google-img.webp";
import googleReview from "../../../../Assets/images/home/google-review-1.png";
import StarIcon from '@mui/icons-material/Star';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import Review from "../../../../Assets/images/swales-img/google.png"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { base_url, postHeader } from '../../../../Helpers/request';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Container from '../ui/Container';
import Heading from '../ui/Heading';
// import SlickLeft from "../Assets/img/left.png"
// import SlickRight from "../Assets/img/right.png"
import { isDesktop, isMobile } from 'react-device-detect';
import SlickLeft from "../../../../Assets/images/swales-img/left-white.png"
import SlickRight from "../../../../Assets/images/swales-img/right-white.png"
import { FamilyRestroomTwoTone } from '@mui/icons-material';

const Customer = () => {
    const [customerData,setCustomerData] = React.useState([])
    React.useEffect(()=>{
        GetCustomerRewies();
        console.log('customerdta',customerData)
     },[])
     const GetCustomerRewies = () =>{
         axios.get(`${base_url}/common/site_reviews`,{
            headers: postHeader
        }).then(res=>{
            console.log(res)
            if(res.data.status == 1){
                setCustomerData(res.data)
            }
        }).catch(error=>{
            console.log(error)
        })
     }

    let settings2 = {
        dots: false,
        infinite: true,
        speed: 500,
        accessibility: true,
        arrows: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: false,
        prevArrow: <Image src={SlickLeft} alt='left arrow' />,
        nextArrow: <Image src={SlickRight} alt='Right arrow'  />,
        responsive: [
           
            {
                breakpoint: 1479,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    arrows: true,
                }
            },
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: true,
                }
            },
           
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    arrows: true,
                }
            }
            
        ]
    };
    const swaleReviewData = [
        {
            reName:"Tegan Blackhurst",
            reText:"We purchased our  wedding bands with Swales and are super impressed with them! I initially saw Jake at the NEC Wedding Show  and spotted a diamond and platinum band which perfectly matched my engagement ring.",
            reTime:"3 weeks ago",
        },
        {
            reName:"Emily Markwick",
            reText:"We met Jake at a wedding fayre and after having a quick chat and looking at the beautiful selection of rings Swales had to offer we booked a private appointment to choose our wedding rings. From start to finish Jake was completely ",
            reTime:"3 weeks ago",
        },
        {
            reName:"Kyla Jordan",
            reText:"Met Jake at a wedding fair. He was very helpful and explained my ring would be made from scratch which I thought was lovely as it makes your ring feel more special. After ordering Jake kept in touch and let me know when my ring was ready.",
            reTime:"5 months ago",
        },
        {
            reName:"Jamie Court",
            reText:"We could not recommend anyone better!! Jake was absolutely outstanding with helping us with the ring. showing us a 3d design as well as a real life model of what it would actually …",
            reTime:"3 weeks ago",
        },
        {
            reName:"Georgina Brannigan",
            reText:"Couldn't recommend Swales more! After helping my fiancé design my engagement ring, we returned to Jake to select our wedding rings. Jake listened to our thoughts and immediately found the perfect wedding band for me! Excellent guidance on …",
            reTime:"3 weeks ago",
        },
    ]

    return (
        <section className="rcs_customer_say_section">
            <Container className="showcase-container">
                    <div className='d-flex align-items-center justify-content-between pb-3'>
                        <Heading className='text-left p-0'>
                            <h2>Hear what our customers have to say</h2>
                            <p>Customer Reviews</p>
                        </Heading>

                       {/* {isDesktop &&  <div className="rcs_customer_review_btn">
                            <Button className="btn outline-base-btn large" onClick={() => window.open(customerData?.google_review_url, "_blank")}> Write a Review </Button>
                        </div>} */}
                    </div>
                    
                <Row>
                    <Col lg={12} data-aos="fade-up" data-aos-duration="1400">
                        <div className="rcs_customer_review_slider">
                            <Slider className="rcs_customer_say_inner" {...settings2}>
                            {swaleReviewData.map(customdata => 
                                <div className="rcs_customer_review_slide_item">
                                    <div className="rcs_customer_slide_title_img mb-4">
                                        <div className="rcs_customer_review_slide_img">
                                            <LazyLoadImage src={Review} alt="Google Review Image"  onClick={() => window.open("https://www.google.com/search?q=swales+diamond+uk&oq=swales+diamond+uk&gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIGCAEQRRg8MgYIAhBFGDzSAQg3Mjg3ajBqN6gCALACAA&sourceid=chrome&ie=UTF-8#lrd=0x4870bd920ebdadf1:0xcf7ac28d835cef73,1,,,", "_blank")}/>
                                        </div>
                                    </div>
                                    <div className="rcs_customer_slide_text_content mb-4">
                                        {/* <p>
                                            {(customdata?.review)?.slice(0,150)}{ customdata?.review?.length > 150 ? '...' : ""}
                                        </p> */}
                                        <p>
                                            {customdata.reText}
                                        </p>
                                        <Link to="#" onClick={() => window.open("https://www.google.com/search?q=swales+diamond+uk&oq=swales+diamond+uk&gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIGCAEQRRg8MgYIAhBFGDzSAQg3Mjg3ajBqN6gCALACAA&sourceid=chrome&ie=UTF-8#lrd=0x4870bd920ebdadf1:0xcf7ac28d835cef73,1,,,", "_blank")}>  Read More </Link>
                                        <div>
                                             <Rating name="half-rating-read" defaultValue={5}  size="medium" readOnly style={{color:"#F4C01E", fontSize:"22px", marginTop:"15px"}}/>
                                        </div>
                                    </div>
                                    <div className='rcs_customer_review_footer d-flex align-items-center justify-content-between'>
                                        {/* <div className='name-charAt mr-3'>{customdata?.reName.charAt(0)}</div> */}
                                        <div className="rcs_customer_review_slide_title ">
                                            <h3>{customdata?.reName}</h3>
                                        </div>
                                        <div className='time'>
{/* <p className='mb-0' style={{color:"#b3b3b3"}}>{customdata.reTime}</p> */}
                                        </div>
                                    </div>
                                </div>)}
                            </Slider>
                        </div>
                    </Col>
                </Row>

                {/* {isMobile &&  <div className="rcs_customer_review_btn text-center mt-5">
                            <Button className="btn outline-base-btn large" onClick={() => window.open(customerData?.google_review_url, "_blank")}> Write a Review </Button>
                        </div>} */}
            </Container>
        </section>
    );
}

export default Customer;
