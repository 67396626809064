import React from 'react'
import '../../../../../src/Assets/css/swalesStyle.css'
import { Col, Row } from 'react-bootstrap'
import Heading from '../ui/Heading'
import Container from '../ui/Container'
import { useHistory } from 'react-router-dom'

const EngagementWeddingRings = () => {
    const history = useHistory();
  return (
    <div className='sd_Engagement_wedding_Rings_section'>
      <Container className='showcase-container'>
        <Row>
            <Col sm={12} md={6} lg={6}>
                <div className='sd_eng_bg_img'>
                <Heading className="text-left">
          <h2>Engagement <br></br>
Rings</h2>
          {/* <p>The Art of Geometry</p> */}
          <button className='sd_btn_outline' onClick={()=> history.push("/engagement-rings")}> Shop Now</button>
        </Heading>
                </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
            <div className='sd_wedd_bg_img'>
                <Heading className="text-left">
          <h2>Wedding <br></br>
Rings</h2>
          {/* <p>The Art of Geometry</p> */}
          <button className='sd_btn_outline' onClick={()=> history.push("/jewellery/wedding-rings")}> Shop Now</button>
        </Heading>
                </div>
            </Col>
        </Row>
      </Container>
    </div>
  )
}

export default EngagementWeddingRings
