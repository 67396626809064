import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Heading from '../ui/Heading'
import { useHistory } from 'react-router-dom'

const LooseDiamonds = () => {
    const history = useHistory()
  return (
    <Container className='showcase-container'>
        <Row>
            <Col sm={12} md={12} lg={12} className="p-0"> 
            <div className='sd_loose_diamond_Section'>
                <div>
                <Heading className='text-start pb-2'>
                                <h2>Loose <br></br> Diamonds</h2>
                            </Heading>
                            <div>
                                <button className='sd_btn_outline' onClick={()=> history.push("/diamonds")}>Shop Now</button>
                            </div>
                </div>
           
      </div>
            </Col>
        </Row>
      
    </Container>
   
  )
}

export default LooseDiamonds
