import { createSlice } from "@reduxjs/toolkit";

const homeSlice = createSlice({
  name: "home",
  initialState: {
    currency: [],
    session_id: "",
    currencycode: "",
    currencyicon: "",
    currencyflag: "",
    menu: [],
    logindrawer: false,
    userData: [],
  },
  reducers: {
    sessionId: (state, action) => {
      state.session_id = action.payload;
    },
    currency: (state, action) => {
      state.currency = action.payload;
      if (state.currencycode == "") {
        state.currencycode = action.payload[0].currency_code;
      }
      if (state.currencyicon == "") {
        state.currencyicon = action.payload[0].currency_icon;
      }
      if (state.currencyflag == "") {
        state.currencyflag = action.payload[0].country_flag;
      }
    },
    currencyIcon: (state, action) => {
      state.currencyicon = action.payload;
    },
    currencyCode: (state, action) => {
      state.currencycode = action.payload;
    },
    currencyFlag: (state, action) => {
      state.currencyflag = action.payload;
    },
    menu: (state, action) => {
      state.menu = action.payload;
    },
    loginDrawer: (state, action) => {
      state.logindrawer = action.payload;
    },
    Setuser: (state, action) => {
      state.userData = action.payload;
    },
  },
});
export const {
  sessionId,
  currency,
  currencyIcon,
  currencyCode,
  currencyFlag,
  menu,
  loginDrawer,
  Setuser,
} = homeSlice.actions;
export default homeSlice.reducer;
