import React, { useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import "../../Assets/css/home.css";
import { isMobile, isMobileOnly } from "react-device-detect";
import { Skeleton } from "@mui/material";
import { useHistory } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";
import axios from "axios";
import { base_url, postHeader } from "../../Helpers/request";
import { useState } from "react";
import { toast } from "react-toastify";
import LazyLoad from "react-lazy-load";
import BannerDesktop from "../../Assets/images/swales-img/home-banner.png";
import BannerMobile from "../../Assets/images/swales-img/home-banner-mobile.png";

const BannerSlider = () => {
  const history = useHistory();
  const [banner, setBanner] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    //-----------------------------------Banner
    setLoading(true);
    axios
      .get(base_url + `/home/slider`, {
        headers: postHeader,
      })
      .then((response) => {
        if (response.data.status == 1) {
          setLoading(false);
          setBanner(response.data.data);
          localStorage.setItem(
            "bw-bannerdata",
            JSON.stringify(response.data.data)
          );
        } else {
          toast.error(response.message, { autoClose: 3000 });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      {!banner ? (
        <Skeleton
          variant="text"
          animation="wave"
          //height={750}
          className="bannerskel"
        />
      ) : (
        banner?.map((val, index) => (
          <>
            {!loading ? (
              <div
                className="rcs_hero_slider"
                style={{
                  backgroundImage: `url(${
                    // isMobileOnly ? BannerMobile : BannerDesktop
                    isMobileOnly ? val?.mobile_image : val?.image
                  })`,
                }}
              >
                <div className="rcs_hero_img">
                  <Container className="showcase-container">
                    <Row className="m-auto w-100">
                      <Col md={6} className="p-0">
                        <div
                          className={`${
                            isMobile ? "text-center" : val.text_direction_class
                          } text-center`}
                        >
                          <h1 data-aos="fade-up-left" data-aos-duration="700">
                            {val.title}
                            {/* Engagement Rings */}
                          </h1>
                          <p
                            data-aos="fade-up-left"
                            data-aos-duration="1200"
                            data-aos-delay="300"
                          >
                            {val.short_text}
                            {/* Uncover the Brilliance and Beauty */}
                          </p>
                          <Button
                            data-aos="fade-up-left"
                            data-aos-duration="1800"
                            data-aos-delay="400"
                            // onClick={() => history.push("/engagement-rings")}
                            onClick={() => {
                              history.push("/" + val.btn_link);
                            }}
                            className="outline-base-btn large"
                          >
                            {val.btn_text}
                            {/* Shop Now */}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            ) : (
              <Skeleton variant="text" animation="wave" />
            )}
          </>
        ))
      )}
    </>
  );
};

export default BannerSlider;
