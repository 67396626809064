import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import member from '../../../../Assets/images/swales-img/member-img.webp'

const Member = () => {
  return (
    <div className='member_banner'>
<Container className='showcase-container' >
    <Row>
        <Col sm={12} md={12} lg={12}>

    <Image src={member} alt="member Image"/>
        </Col>
    </Row>

</Container>
    </div>
  )
}

export default Member