import React from 'react'
import Container from '../ui/Container'
import { Col, Image, Row } from 'react-bootstrap'
import AboutImg from "../../../../Assets/images/swales-img/aboutus.jpg"
import Heading from '../ui/Heading'
import { Link } from 'react-router-dom/cjs/react-router-dom'


const CustomDesign = () => {
    return (
        <section className='custom-design section-space'>
            <Container className="showcase-container">
                <Row className='align-items-center'>
                    <Col md={5} xs={12} className='text-center'>
                        <Image src={AboutImg} alt='About Image' className='img-fluid' />
                    </Col>

                    <Col md={7} xs={12}>
                        <Heading className='text-justify'>
                            <h2 className='text-uppercase'>About Us</h2>
                            <p className='mb-3'>Welcome to Swales, where the artistry of fine jewellery meets the personalised touch of bespoke design. With almost two decades of expertise in gemmology and a passion for crafting exquisite pieces, we're thrilled to bring our unique vision to you.</p>
                            <p className='mb-3'>At Swales, we believe that jewellery is more than just an accessory; it's a reflection of individuality, love, and cherished moments. Our mission is to provide you with not just stunning pieces, but with an experience that is as special and unique as the jewellery we create.</p>

                            <p>Swales Diamonds - Best Jewellers in Birmingham. It is one of the best Jewellery store in Birmingham. We are passionate jewellery designers with an experience of 20+ years.</p>
                            <div className='sd_about_btn_left mt-4'>
                                <Link to='/our-story' className="btn outline-base-btn large ">Read More</Link>
                            </div>
                        </Heading>

                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default CustomDesign