import React, { useState } from 'react'
import Heading from '../ui/Heading'
import Container from '../ui/Container'
import { Button, Col, Image, Row } from 'react-bootstrap'
import Round from "../Assets/img/diamond-shape/round-diamond.png";
import Princess from "../Assets/img/diamond-shape/princess-diamond.png";
import Cushion from "../Assets/img/diamond-shape/cushion-diamond.png";
import Emerald from "../Assets/img/diamond-shape/emerald-diamond.png";
import Oval from "../Assets/img/diamond-shape/oval-diamond.png";
import Radiant from "../Assets/img/diamond-shape/radiant-diamond.png";
import Asscher from "../Assets/img/diamond-shape/asscher-diamond.png";
import Marquise from "../Assets/img/diamond-shape/marquise-diamond.png";
import Heart from "../Assets/img/diamond-shape/heart-diamond.png";
import Pear from "../Assets/img/diamond-shape/pear-diamond.png";
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { isMobile, isTablet } from 'react-device-detect';
import Slider from "react-slick";
import divider from '../../../../Assets/newImg/Divider.png'
import SlickLeft from "../../../../Assets/images/swales-img/left-white.png"
import SlickRight from "../../../../Assets/images/swales-img/right-white.png"
import { useHistory } from 'react-router-dom';
const data = [
  {
    name: "Round",
    para: "Cut with the optimum light refraction and sparkle.",
    image: Round,
    url: '/diamonds/shape=Round'
  },
  {
    name: "Princess",
    para: "A contemporary cut, with optimal fire and brilliance.",
    image: Princess,
    url: '/diamonds/shape=Princess'
  },
  {
    name: "Cushion",
    para: "With soft corners, a beautiful alternative to a round or princess cut.",
    image: Cushion,
    url: '/diamonds/shape=Cushion'
  },
  {
    name: "Emerald",
    para: "This stylish cut creates rectangular facets with great stone's clarity.",
    image: Emerald,
    url: '/diamonds/shape=Emerald'
  },
  {
    name: "Oval",
    para: "Optimises carat, giving the illusion of a larger stone.",
    image: Oval,
    url: '/diamonds/shape=Oval'
  },
  {
    name: "Radiant",
    para: "The most brilliant of the rectangular cut diamonds.",
    image: Radiant,
    url: '/diamonds/shape=Radiant'
  },
  {
    name: "Asscher",
    para: "This unique shape is a square emerald, with linear facets.",
    image: Asscher,
    url: '/diamonds/shape=Asscher'
  },
  {
    name: "Marquise",
    para: "Elongate the finger of the wearer with a look of great grandeur.",
    image: Marquise,
    url: '/diamonds/shape=Marquise'
  },
  {
    name: "Heart",
    para: "A rare cut that is a true testament of your relationship.",
    image: Heart,
    url: '/diamonds/shape=Heart'
  },
  {
    name: "Pear",
    para: "Combining the brilliance and design of the Round and Marquise cuts.",
    image: Pear,
    url: '/diamonds/shape=Pear'
  },
]

const ShopByDiamondShape = () => {
  const history = useHistory()
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <Image src={SlickLeft} alt='left arrow' />,
   nextArrow: <Image src={SlickRight} alt='Right arrow'  />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };
  const [diamondItem, setDiamondItem] = useState(data[0]);

  return (
    <>
    <section className='shop-diamond-by-shape sd_shop_diamond_collection section-space'>
      <Container>
        <Heading>
          <h2>Shop Diamonds By Shape</h2>
          <p>The Art of Geometry</p>
        </Heading>

        <div className='inner-shop-diamond-shape'>
          {isMobile ?
            <div className='diamonds-collection'>
              <Slider {...settings}>
                {
                  data.map((item, i) => <div className='diamond-item cursor-pointer' key={i} onClick={() => setDiamondItem(item)}>
                    <Image src={item.image} alt='Round' />
                    <p className={`${item.name === item.name && "name"}`}>{item.name}</p>
                    {/* <p className={'desc'}>{item.para}</p> */}
                    {/* <Link className='btn outline-base-btn small' to={item.url}>Shop {item.name} Diamond</Link> */}
                  </div>)
                }
              </Slider>
            </div>
            :
          isTablet ?
            <div className='diamonds-collection'>
              <Slider {...settings}>
                {
                  data.map((item, i) => <div className='diamond-item cursor-pointer' key={i} onClick={() => setDiamondItem(item)}>
                    <Image src={item.image} alt='Round' />
                    <p className={`${item.name === item.name && "name"}`}>{item.name}</p>
                    {/* <p className={'desc'}>{item.para}</p> */}
                    {/* <Link className='btn outline-base-btn small' to={item.url}>Shop {item.name} Diamond</Link> */}
                  </div>)
                }
              </Slider>
            </div>
            :
            <Row className='align-items-center'>
              <Col md={12}>
                <div className='diamonds-collection'>
                  <ul>
                 
                  { data.map((item, i) =>   <li>
                    <div className='diamond-item cursor-pointer' key={i} onClick={() => history.push(item.url)}>
                      <Image src={item.image} alt='Round' />
                      <p className={`${diamondItem.name === item.name && "font-weight-bold"}`}>{item.name}</p>
                    </div>
                    </li>
                     ) }
                  </ul>

                
                </div>
              </Col>
{/* 
              <Col md={5}>
                <div className='diamonds-item-brief d-flex align-items-center'>
                  <Image src={diamondItem.image} alt='' />

                  <div className='content'>
                    <h2>{diamondItem.name}</h2>
                    <p>{diamondItem.para}</p>
                    <Link className='btn outline-base-btn small' to={diamondItem.url}>Shop {diamondItem.name} Diamond</Link>
                  </div>
                </div>
              </Col> */}
            </Row>
          }
        </div>
      </Container>
    </section>
        {/* {isMobile ? <img className='p-2 img-fluid' src={divider} alt='' /> : ''} */}
    </>
  )
}

export default ShopByDiamondShape